import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { Data, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FarmaciasService
{
  router: Router;

  constructor(private http: CustomHttpClient, router: Router)
  {
    this.router = router;
  }

  // Carregar Farmácias do plano Consumer Care
  public CarregarEstadosConsumerCare()
  {
    let url: string = "buscarestados";
    return this.CarregarConsumerCare(url);
  }

  public CarregarCidadeConsumerCare(estado: string)
  {
    let url: string = "buscarcidades/" + estado;
    return this.CarregarConsumerCare(url);
  }

  public CarregarBairroConsumerCare(estado: string, cidade: string)
  {
    let url: string = "buscarbairros/" + estado + "/" + cidade;
    return this.CarregarConsumerCare(url);
  }

  public CarregarFarmaciasConsumerCare(estado: string, cidade: string, bairro: string)
  {
    let url: string = "buscarfarmacias/" + estado + "/" + cidade + "/" + bairro;
    return this.CarregarConsumerCare(url);
  }

  private CarregarConsumerCare(url: string)
  {
    return this.http.Get("api/cadastroconsumercare/" + url);
  }

  // Carregar Farmácias do plano Odonto Care
  public CarregarEstadosOdontoCare()
  {
    let url: string = "buscarestados";
    return this.CarregarOdontoCare(url);
  }

  public CarregarCidadeOdontoCare(estado: string)
  {
    let url: string = "buscarcidades/" + estado;
    return this.CarregarOdontoCare(url);
  }

  public CarregarBairroOdontoCare(estado: string, cidade: string)
  {
    let url: string = "buscarbairros/" + estado + "/" + cidade;
    return this.CarregarOdontoCare(url);
  }

  public CarregarFarmaciasOdontoCare(estado: string, cidade: string, bairro: string)
  {
    let url: string = "buscarfarmacias/" + estado + "/" + cidade + "/" + bairro;
    return this.CarregarOdontoCare(url);
  }

  private CarregarOdontoCare(url: string)
  {
    return this.http.Get("api/cadastroodontocare/" + url);
  }
}

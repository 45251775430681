import { Injectable } from '@angular/core';
import { CustomHttpClient, IRequestOptions } from './../../providers/CustomHttpClient';
import { Observable } from 'rxjs';
import { Data, Router } from '@angular/router';
import { CartaoBeneficiario } from '../../model/CartaoBeneficiario';
import { Dentista } from 'src/app/model/Dentista';
import { Email } from 'src/app/model/Email';

@Injectable({
  providedIn: 'root'
})
export class CadastroService
{
  router: Router;

  constructor(private http: CustomHttpClient, router: Router)
  {
    this.router = router;
  }
  
  public CriarBeneficiarioConsumerCare(cartaoBeneficiario: CartaoBeneficiario)
  {
    return this.http
                .Post("api/cadastroconsumercare/cadastrar/", cartaoBeneficiario)
                .toPromise();
  }

  public CriarBeneficiarioOdontoCare(cartaoBeneficiario: CartaoBeneficiario)
  {
    return this.http.Post("api/cadastroodontocare/cadastrar/", cartaoBeneficiario);
  }

  public GerarCupons(dentista: Dentista)
  {
    return this.http
                  .Post("api/gerarcupons/gerar/", dentista)
                  .toPromise();
  }

  public ConsultarProdutos()
  {
    return this.http
                  .Post("api/gerarcupons/consultarprodutos/", "")
                  .toPromise();
  }

  public EnviarEmailConsumerCare(email: Email)
  {
    return this.http
                  .Post("api/cadastroconsumercare/enviaremail/", email)
                  .toPromise();
  }

  public EnviarEmailOdontoCare(email: Email)
  {
    return this.http.Post("api/cadastroodontocare/enviaremail/", email);
  }

  public EnviarEmailGerarCupomOdontoCare(email: Email)
  {
    return this.http
                  .Post("api/gerarcupons/enviaremail/", email)
                  .toPromise();
  }
}

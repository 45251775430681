export class DataCEP {
  tipo: number;
  logradouro: string = "";
  numero: string = "";
  complemento: string = "";
  bairro: string = "";
  cidade: string = "";
  uf: string = "";
  cep: string = "";
  referencia: string = "";
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CadastroService } from "../../service/cadastro/cadastro.service";
import { FarmaciasService } from "../../service/farmacias/farmacias.service";
import { Localizador } from "../../model/Localizador";
import { CartaoBeneficiario } from '../../model/CartaoBeneficiario';
import { Retorno } from '../../model/Retorno';
import { Beneficiario } from '../../model/Beneficiario';
import { DadosBeneficiario } from '../../model/DadosBeneficiario';
import { Endereco } from '../../model/Endereco';
import { Telefones } from '../../model/Telefones';
import { DataCEP } from '../../model/DataCEP';
import { Uf } from '../../model/Uf';
import { Email } from '../../../app/model/Email';
declare var $: any;

@Component({
  selector: 'app-cadastro',
  templateUrl: './CadastroPacienteConsumerCare.component.html',
  styleUrls: ['./CadastroPacienteConsumerCare.component.css'],
  providers: [CadastroService, FarmaciasService]
})

export class CadastroPacienteConsumerCareComponent implements OnInit
{
  uflista: Uf[];
  user: CartaoBeneficiario;
  show: boolean;
  planoId: number;
  emailPattern = "^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+).(\.[a-z]{2,3})$";
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";

  public estadosCadastro: any[];
  public cidadesCadastro: any[];
  public cepCadastro: DataCEP;
  public estados: any[];
  public cidades: any[];
  public bairros: any[];
  public estadoSelecionado: string;
  public cidadeSelecionada: string;
  public bairroSelecionado: string;
  public farmacias: any[];
  public esconder: boolean;
  public testeestado: any[];
  public testecidade: any[];
  public testebairro: any[];
  public retorno: Retorno = new Retorno();
  public cpfCupom: string;
  public cepInvalido: boolean;
  public emailv: Email = new Email();
  public carregando: boolean;

  cadastroBeneficiarioForm = new FormGroup({
    nomeBeneficiario: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$")]),
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    telefone: new FormControl('', [Validators.required]),
    cpf: new FormControl('', [Validators.required, Validators.pattern(this.cpfPattern), GenericValidator.isValidCpf()]),
    dataNascimento: new FormControl('', [Validators.required, GenericValidator.validateDate()]),
    sexo: new FormControl('', Validators.required),
    croDentista: new FormControl(),
    ufDentista: new FormControl(),
    aceitotermo: new FormControl('', Validators.requiredTrue),
    aceitopromo: new FormControl()
  });

  constructor(private cadastroService: CadastroService, private farmaciasService: FarmaciasService) { }

  ngOnInit()
  {
    this.user = new CartaoBeneficiario();
    this.user.beneficiario = new Beneficiario();
    this.user.beneficiario.dadosBeneficiario = new DadosBeneficiario();
    this.user.beneficiario.endereco = new Endereco();
    this.user.beneficiario.telefones = new Telefones();
    this.cepCadastro = new DataCEP();
    this.esconder = true;
    this.cepInvalido = false;
    this.estadoSelecionado = "";
    this.cidadeSelecionada = "";
    this.bairroSelecionado = "";
    this.carregando = false;
    this.carregarEstados();

    //$("#emailModal").modal("show");

    this.uflista = [
      { nome: "AC" },
      { nome: "AL" },
      { nome: "AP" },
      { nome: "AM" },
      { nome: "BA" },
      { nome: "CE" },
      { nome: "DF" },
      { nome: "ES" },
      { nome: "GO" },
      { nome: "MA" },
      { nome: "MT" },
      { nome: "MS" },
      { nome: "MG" },
      { nome: "PA" },
      { nome: "PB" },
      { nome: "PR" },
      { nome: "PE" },
      { nome: "PI" },
      { nome: "RJ" },
      { nome: "RN" },
      { nome: "RS" },
      { nome: "RO" },
      { nome: "RR" },
      { nome: "SC" },
      { nome: "SP" },
      { nome: "SE" },
      { nome: "TO" }
    ];

    //$("#ufDentista").value = 0;
    this.cadastroBeneficiarioForm.controls['ufDentista'].setValue(0);
  }

  private carregarEstados()
  {
    this.farmaciasService.CarregarEstadosConsumerCare().subscribe((result: Localizador) =>
    {
      this.estados = result.estados;
    });
  }

  private carregarCidade(estado: string)
  {
    this.farmaciasService.CarregarCidadeConsumerCare(estado).subscribe((result: Localizador) =>
    {
      this.cidades = result.cidades;
    });
  }

  private carregarBairro(estado: string, cidade: string)
  {
    this.farmaciasService.CarregarBairroConsumerCare(estado, cidade).subscribe((result: Localizador) =>
    {
      this.bairros = result.bairros;
    });
  }

  private carregarFarmacias(estado: string, cidade: string, bairro: string)
  {
    this.farmaciasService.CarregarFarmaciasConsumerCare(estado, cidade, bairro).subscribe(result =>
    {
      this.farmacias = result['farmacias'];
      this.esconder = false;
    });
  }

  private onSelectEstado(value)
  {
    this.carregarCidade(value);
    this.cidadeSelecionada = "";
    this.bairroSelecionado = "";
  }

  private onSelectCidade(value)
  {
    this.cidadeSelecionada = value;
    this.carregarBairro(this.estadoSelecionado, this.cidadeSelecionada);
    this.bairroSelecionado = "";
  }

  private onSelectBairro(value)
  {
    this.bairroSelecionado = value;
    this.carregarFarmacias(this.estadoSelecionado, this.cidadeSelecionada, this.bairroSelecionado);
  }

  public ativaTooltipObrigatorio(nomecampo, component)
  {
    var ativa = this.cadastroBeneficiarioForm.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    
    if (ativa)
    {
      campoObrigatorioerror = this.cadastroBeneficiarioForm.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component)
  {
    var ativa = this.cadastroBeneficiarioForm.controls[nomecampo].invalid;
    var cpfinvalido = false;
    var dataInvalida = false;
    var patterninvalido = false;
    if (ativa) {
      cpfinvalido = this.cadastroBeneficiarioForm.controls[nomecampo].errors.cpfNotValid;
      dataInvalida = this.cadastroBeneficiarioForm.controls[nomecampo].errors.dataNotValid;
      patterninvalido = this.cadastroBeneficiarioForm.controls[nomecampo].errors.pattern;
      cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
      dataInvalida = (dataInvalida !== null && dataInvalida) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (cpfinvalido || patterninvalido || dataInvalida);

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component)
  {
    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }

  public limparCampos()
  {
    this.cadastroBeneficiarioForm = new FormGroup({
      nomeBeneficiario: new FormControl('', [Validators.required, Validators.pattern("^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$")]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      telefone: new FormControl('', [Validators.required]),
      cpf: new FormControl('', [Validators.required, Validators.pattern(this.cpfPattern), GenericValidator.isValidCpf()]),
      dataNascimento: new FormControl('', [Validators.required, GenericValidator.validateDate()]),
      sexo: new FormControl('', Validators.required),
      croDentista: new FormControl(),
      ufDentista: new FormControl(),
      aceitotermo: new FormControl('', Validators.requiredTrue),
      aceitopromo: new FormControl()
    });

    this.cadastroBeneficiarioForm.controls['ufDentista'].setValue(0);

  }

  public Imprimir()
  {
    $(".printable").printThis({
      debug: false,               // show the iframe for debugging
      importCSS: true,            // import parent page css
      importStyle: false,         // import style tags
      printContainer: true,       // print outer container/$.selector
      loadCSS: "",                // path to additional css file - use an array [] for multiple
      pageTitle: "",              // add title to print page
      removeInline: false,        // remove inline styles from print elements
      removeInlineSelector: "*",  // custom selectors to filter inline styles. removeInline must be true
      printDelay: 333,            // variable print delay
      header: null,               // prefix to html
      footer: null,               // postfix to html
      base: false,                // preserve the BASE tag or accept a string for the URL
      formValues: true,           // preserve input/form values
      canvas: false,              // copy canvas content
      doctypeString: '...',       // enter a different doctype for older markup
      removeScripts: false,       // remove script tags from print content
      copyTagClasses: false,      // copy classes from the html & body tag
      beforePrintEvent: null,     // function for printEvent in iframe
      beforePrint: null,          // function called before iframe is filled
      afterPrint: null            // function called before iframe is removed
    });
  }

  public AnimacaoComoFunciona()
  {
    $('html, body').animate({
      scrollTop: $('#como-funciona').offset().top
    }, 1000);
  }

  public AnimacaoOndeEncontrar()
  {
    $('html, body').animate({
      scrollTop: $('#onde-encontrar').offset().top
    }, 1000);
  }

  public AnimacaoCadastrar()
  {
    $('html, body').animate({
      scrollTop: $('#cadastre-se').offset().top
    }, 1000);
  }

  public abrirTermo()
  {
    $('#TermoModal').modal('show');
  }

  public EscolherEmail()
  {
    $('.modal')
      .on({
        'show.bs.modal': function () {
          var idx = $('.modal:visible').length;

          $(this).css('z-index', 1040 + (10 * idx));
          var url = $(this).find('[data-url]').data('url');
          if (url != undefined && url != '') {
            var id = $(this).attr('id');
            $('#' + id + ' .modal-body').load(url);
          }
        },
        'shown.bs.modal': function () {
          var idx = ($('.modal:visible').length) - 1; // raise backdrop after animation.
          $('.modal-backdrop').not('.stacked')
            .css('z-index', 1039 + (10 * idx))
            .addClass('stacked');
        },
        'hidden.bs.modal': function () {
          if ($('.modal:visible').length > 0) {
            // restore the modal-open class to the body element, so that scrolling works
            // properly after de-stacking a modal.
            setTimeout(function () {
              $(document.body).addClass('modal-open');
            }, 0);
          }
        }
      });

    $("#myModal2").modal('hide');
    $("#emailModal").modal('show');
  }

  public fecharEmail()
  {
    $("#myModal3 .close").click()
    $("#myModal2").modal('show');
  }

  public fecharEmail2()
  {
    $("#emailModal .close").click()
    $("#myModal2").modal('show');
  }

  public EmailEnviado()
  {
    $("#myModal3 .close").click()
    $("#myModal2").modal('show');
  }

  public EnviarEmail()
  {
    var enviou;

    this.carregando = true;

    this.emailv.destinatario = $("#emailEnviado").val();
    this.emailv.numeroCupom = this.cpfCupom;
    this.emailv.nomeDentista = this.user.beneficiario.dadosBeneficiario.nomeBeneficiario;
    this.emailv.descontos = [];

    this.cadastroService
          .EnviarEmailConsumerCare(this.emailv)
          .then((res: Retorno) =>
          {
            enviou = res.sucesso;
            
            if (enviou)
            {
              $("#emailModal .close").click()
              $("#myModal3").modal('show');
            }
            else
            {
              this.retorno.erro = "Erro ao enviar e-mail"
              $('#myModalErro').modal('show');
            }
            
            this.carregando = false;
          })
          .catch(() => this.mostrarErroInterno);
  }
  
  public onCadastrar()
  {
    this.carregando = true;

    this.cpfCupom = this.cadastroBeneficiarioForm.controls['cpf'].value.toString();
    
    this.user.beneficiario.dadosBeneficiario = {
      nomeBeneficiario: this.cadastroBeneficiarioForm.controls['nomeBeneficiario'].value,
      cpf: this.cadastroBeneficiarioForm.controls['cpf'].value.toString().replace(/\.|\-/g, ''),
      rg: "",
      dataNascimento: this.cadastroBeneficiarioForm.controls['dataNascimento'].value,
      sexo: this.cadastroBeneficiarioForm.controls['sexo'].value,
      email1: this.cadastroBeneficiarioForm.controls['email'].value,
      email2: "",
    };

    this.user.beneficiario.endereco = {
      cep: "",
      numero: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      uf: "",
      complemento: "",
      tipoEndereco: ""
    };

    this.user.beneficiario.telefones = {
      residencial: this.cadastroBeneficiarioForm.controls['telefone'].value.toString().replace(/\(|\)|\-|\s/g, ''),
      celular: this.user.beneficiario.telefones.residencial,
      comercial: ""
    };

    if (this.cadastroBeneficiarioForm.controls['croDentista'].value != null)
      this.user.croDentista = this.cadastroBeneficiarioForm.controls['croDentista'].value;
    else
      this.user.croDentista = "";

    if (this.cadastroBeneficiarioForm.controls['ufDentista'].value != null)
      this.user.ufDentista = (this.user.croDentista == "" ? "" : this.cadastroBeneficiarioForm.controls['ufDentista'].value);
    else
      this.user.ufDentista = "";

    if (this.cadastroBeneficiarioForm.controls['aceitopromo'].value != null)
      this.user.aceito_SMS_e_Email = this.cadastroBeneficiarioForm.controls['aceitopromo'].value;
    else
      this.user.aceito_SMS_e_Email = false;

    this.cadastroService
          .CriarBeneficiarioConsumerCare(this.user)
          .then((res: Retorno) =>
          {
            this.retorno = res;
            
            if (res.sucesso)
              $('#myModal2').modal('show');
            else
              $('#myModalErro').modal('show');

            this.carregando = false;
          })
          .catch(() => this.mostrarErroInterno);
  }

  public mostrarErroInterno()
  {
    this.retorno.erro = "Erro interno";
    $('#myModalErro').modal('show');
    this.carregando = false;
  }

  public cssObrigatorio(nomecampo)
  {
    if (this.cadastroBeneficiarioForm.controls[nomecampo].invalid && (this.cadastroBeneficiarioForm.controls[nomecampo].touched || this.cadastroBeneficiarioForm.controls[nomecampo].dirty)) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.cadastroBeneficiarioForm.controls[nomecampo].valid) {
      $("#" + nomecampo).css("border-color", "white");
    }
  }
}

export class GenericValidator
{
  constructor() { }

  /**
  * Valida se a data é valida
  */
  static validateDate()
  {
    return (control: AbstractControl): Validators =>
    {
      var RegExPattern = /^((((0?[1-9]|[12]\d|3[01])[\.\-\/](0?[13578]|1[02])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|[12]\d|30)[\.\-\/](0?[13456789]|1[012])[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|((0?[1-9]|1\d|2[0-8])[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?\d{2}))|(29[\.\-\/]0?2[\.\-\/]((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00)))|(((0[1-9]|[12]\d|3[01])(0[13578]|1[02])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|[12]\d|30)(0[13456789]|1[012])((1[6-9]|[2-9]\d)?\d{2}))|((0[1-9]|1\d|2[0-8])02((1[6-9]|[2-9]\d)?\d{2}))|(2902((1[6-9]|[2-9]\d)?(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00)|00))))$/;
      if (control.value.toString()) {
        if (!((control.value.match(RegExPattern)) && (control.value != ''))) {
          return { dataNotValid: true };
        }
        else
          return null;
      }

      return null;
    };
  }

  /**
   * Valida se o CPF é valido
  */
  static isValidCpf()
  {
    return (control: AbstractControl): Validators => {
      const cpf = control.value.toString().replace(/\.|\-/g, '');
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}

import { DadosBeneficiario } from "./DadosBeneficiario";
import { Endereco } from "./Endereco";
import { Telefones } from "./Telefones";

export class Beneficiario {
  cartaoTitular: string;
  cartaoUsuario: string;
  planoCodigo: string;
  inicioVigencia: string;
  fimVigencia: string;
  matricula: string;
  grauDependencia: string;
  tipoBeneficiario: string;
  dadosBeneficiario: DadosBeneficiario;
  endereco: Endereco;
  telefones: Telefones;
}

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/Home/Home.component';
import { CadastroPacienteConsumerCareComponent } from './pages/CadastroConsumerCarePaciente/CadastroPacienteConsumerCare.component';
import { CadastroPacienteOdontoCareComponent } from './pages/CadastroOdontoCarePaciente/CadastroPacienteOdontoCare.component';
import { GerarCuponsOdontoCareComponent } from './pages/GerarCuponsOdontoCare/GerarCuponsOdontoCare.component';

import { CadastroService } from "./service/cadastro/cadastro.service";
import { FarmaciasService } from "./service/farmacias/farmacias.service";
import { CustomHttpClient, CustomHttpClientCreator } from './providers/CustomHttpClient';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTableModule } from "angular-6-datatable";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import * as $ from 'jquery';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CadastroPacienteConsumerCareComponent,
    CadastroPacienteOdontoCareComponent,
    GerarCuponsOdontoCareComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxUploaderModule,
    NgSelectModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    DataTableModule,
    AppRoutingModule
  ],
  providers: [CadastroService, FarmaciasService, { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: CustomHttpClient,
      useFactory: CustomHttpClientCreator,
      deps: [HttpClient, NgxSpinnerService]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/Home/Home.component';
import { CadastroPacienteConsumerCareComponent } from './pages/CadastroConsumerCarePaciente/CadastroPacienteConsumerCare.component';
import { CadastroPacienteOdontoCareComponent } from './pages/CadastroOdontoCarePaciente/CadastroPacienteOdontoCare.component';
import { GerarCuponsOdontoCareComponent } from './pages/GerarCuponsOdontoCare/GerarCuponsOdontoCare.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'cadastro', component: CadastroPacienteConsumerCareComponent },
  { path: 'valideseucupom', component: CadastroPacienteOdontoCareComponent },
  { path: 'profissional', component: GerarCuponsOdontoCareComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

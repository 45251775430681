import { Component, OnInit, NgModule } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CadastroService } from "../../service/cadastro/cadastro.service";
import { FarmaciasService } from "../../service/farmacias/farmacias.service";
import { Localizador } from "../../model/Localizador";
import { Router, ActivatedRoute } from '@angular/router';
import { CartaoBeneficiario } from '../../model/CartaoBeneficiario';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Retorno } from '../../model/Retorno';
import { RetornoProdutos } from '../../model/RetornoProdutos';
import { Beneficiario } from '../../model/Beneficiario';
import { DadosBeneficiario } from '../../model/DadosBeneficiario';
import { Endereco } from '../../model/Endereco';
import { Telefones } from '../../model/Telefones';
import { CEP } from '../../model/CEP';
import { Estados } from '../../model/Estados';
import { DataCEP } from '../../model/DataCEP';
import { Uf } from '../../model/Uf';
import { Dentista } from '../../model/Dentista';
import { Produtosformulario } from '../../model/Produtosformulario';
import * as jsPDF from 'jspdf'
import { Email } from '../../model/Email';
declare var $: any;


@Component({
  selector: 'app-gerarCupons',
  templateUrl: './GerarCuponsOdontoCare.component.html',
  styleUrls: ['./GerarCuponsOdontoCare.component.css'],
  providers: [CadastroService, FarmaciasService]
})

export class GerarCuponsOdontoCareComponent implements OnInit
{
  uflista: Uf[];
  user: Dentista;
  show: boolean;
  planoId: number;
  cpfPattern = "^[0-9]{3}\.[0-9]{3}\.[0-9]{3}\-[0-9]{2}$";
  public descontoProduto1: string;
  public descontoProduto2: string;
  public descontoProduto3: string;
  public descontoProduto4: string;
  public descontoProduto5: string;
  public descontoProduto6: string;
  public descontoProduto7: string;
  public descontoProduto8: string;
  public estadosCadastro: any[];
  public cidadesCadastro: any[];
  public cepCadastro: DataCEP;
  public estados: any[];
  public cidades: any[];
  public bairros: any[];
  public estadoSelecionado: string;
  public cidadeSelecionada: string;
  public bairroSelecionado: string;
  public farmacias: any[];
  public esconder: boolean;
  public testeestado: any[];
  public testecidade: any[];
  public testebairro: any[];
  public retorno: Retorno = new Retorno();
  public retornoProdutos: RetornoProdutos = new RetornoProdutos();
  public numeroCupom: string;
  public cepInvalido: boolean;
  public paginaAtual: number;
  public paginaTotal: number;
  public paginaIndex: number;
  public nomeDentista: string;
  public xepOnline: any;
  public emailv: Email = new Email();
  public emailDestinatario: string;
  public cupons: string[]
  // public carregandoGerar: boolean;
  public carregando: boolean;

  gerarCupomDentistaForm = new FormGroup({
    cpf: new FormControl('', [Validators.required, Validators.pattern(this.cpfPattern), GenericValidator.isValidCpf()]),
    croDentista: new FormControl('', Validators.required),
    ufDentista: new FormControl('', Validators.required),
    qtdCupons: new FormControl('', Validators.required),
  });

  constructor(private cadastroService: CadastroService,
              private farmaciasService: FarmaciasService) { }

  ngOnInit()
  {
    this.user = new Dentista();
    this.emailv = new Email();
    this.retornoProdutos.produtos = new Produtosformulario();
    this.esconder = true;
    this.cepInvalido = false;
    this.estadoSelecionado = "";
    this.cidadeSelecionada = "";
    this.bairroSelecionado = "";
    // this.carregandoGerar = false;
    this.carregando = false;
    this.carregarEstados();

    this.uflista = [
      { nome: "AC" },
      { nome: "AL" },
      { nome: "AP" },
      { nome: "AM" },
      { nome: "BA" },
      { nome: "CE" },
      { nome: "DF" },
      { nome: "ES" },
      { nome: "GO" },
      { nome: "MA" },
      { nome: "MT" },
      { nome: "MS" },
      { nome: "MG" },
      { nome: "PA" },
      { nome: "PB" },
      { nome: "PR" },
      { nome: "PE" },
      { nome: "PI" },
      { nome: "RJ" },
      { nome: "RN" },
      { nome: "RS" },
      { nome: "RO" },
      { nome: "RR" },
      { nome: "SC" },
      { nome: "SP" },
      { nome: "SE" },
      { nome: "TO" }
    ];
  }

  private primeiraPagina()
  {
    this.numeroCupom = this.retorno.tokens[0];
    this.paginaIndex = 0;
    this.paginaAtual = 1;
  }

  private anteriorPagina()
  {
    var indexTemp = (((this.paginaIndex - 1) < 0) ? 0 : (this.paginaIndex - 1));
    this.numeroCupom = this.retorno.tokens[indexTemp];
    this.paginaIndex = indexTemp;
    this.paginaAtual = indexTemp + 1;
  }

  private proximaPagina()
  {
    var indexTemp = (((this.paginaIndex + 1) > (this.paginaTotal - 1)) ? (this.paginaTotal - 1) : (this.paginaIndex + 1));
    this.numeroCupom = this.retorno.tokens[indexTemp];
    this.paginaIndex = indexTemp;
    this.paginaAtual = indexTemp + 1;
  }

  private ultimaPagina()
  {
    this.numeroCupom = this.retorno.tokens[this.retorno.tokens.length - 1];
    this.paginaIndex = this.retorno.tokens.length - 1;
    this.paginaAtual = this.paginaTotal;
  }

  private carregarEstados()
  {
    this.farmaciasService.CarregarEstadosOdontoCare().subscribe((result: Localizador) => {
      this.estados = result.estados;
    });
  }

  private carregarCidade(estado: string)
  {
    this.farmaciasService
          .CarregarCidadeOdontoCare(estado)
          .subscribe((result: Localizador) =>
          {
            this.cidades = result.cidades;
          });
  }

  private carregarBairro(estado: string, cidade: string)
  {
    this.farmaciasService.CarregarBairroOdontoCare(estado, cidade).subscribe((result: Localizador) => {
      this.bairros = result.bairros;
    });
  }

  private carregarFarmacias(estado: string, cidade: string, bairro: string)
  {
    this.farmaciasService.CarregarFarmaciasOdontoCare(estado, cidade, bairro).subscribe(result => {
      this.farmacias = result['farmacias'];
      this.esconder = false;
    });
  }
  
  private onSelectEstado(value)
  {
    this.carregarCidade(value);
    this.cidadeSelecionada = "";
    this.bairroSelecionado = "";
  }

  private onSelectCidade(value)
  {
    this.cidadeSelecionada = value;
    this.carregarBairro(this.estadoSelecionado, this.cidadeSelecionada);
    this.bairroSelecionado = "";
  }

  private onSelectBairro(value)
  {
    this.bairroSelecionado = value;
    this.carregarFarmacias(this.estadoSelecionado, this.cidadeSelecionada, this.bairroSelecionado);
  }

  public ativaTooltipObrigatorio(nomecampo, component)
  {
    var ativa = this.gerarCupomDentistaForm.controls[nomecampo].invalid;
    var campoObrigatorioerror = false;
    if (ativa) {
      campoObrigatorioerror = this.gerarCupomDentistaForm.controls[nomecampo].errors.required;
      campoObrigatorioerror = (campoObrigatorioerror !== null && campoObrigatorioerror) ? true : false;
    }

    ativa = ativa && campoObrigatorioerror;

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltipcampos(nomecampo, component)
  {
    var ativa = this.gerarCupomDentistaForm.controls[nomecampo].invalid;
    var cpfinvalido = false;
    var patterninvalido = false;
    if (ativa) {
      cpfinvalido = this.gerarCupomDentistaForm.controls[nomecampo].errors.cpfInvalido;
      patterninvalido = this.gerarCupomDentistaForm.controls[nomecampo].errors.pattern;
      cpfinvalido = (cpfinvalido !== null && cpfinvalido) ? true : false;
      patterninvalido = (patterninvalido !== null && patterninvalido) ? true : false;
    }

    ativa = ativa && (cpfinvalido || patterninvalido);

    this.ativaTooltip(ativa, component);
  }

  public ativaTooltip(ativa, component)
  {
    var acao1 = ativa ? 'enable' : 'hide';
    var acao2 = ativa ? 'show' : 'disable';
    $(component).tooltip(acao1);
    $(component).tooltip(acao2);
  }

  public limparCampos()
  {
    this.gerarCupomDentistaForm = new FormGroup({
      cpf: new FormControl('', [Validators.required, Validators.pattern(this.cpfPattern), GenericValidator.isValidCpf()]),
      croDentista: new FormControl('', Validators.required),
      ufDentista: new FormControl('', Validators.required),
      qtdCupons: new FormControl('', Validators.required),
    });
  }

  public Imprimir()
  {
    $(".printable").printThis({
      debug: false,                   // show the iframe for debugging
      importCSS: true,                // import parent page css
      importStyle: true,             // import style tags
      printContainer: true,           // grab outer container as well as the contents of the selector
      loadCSS: "",                    // path to additional css file - use an array [] for multiple
      pageTitle: "",                  // add title to print page
      removeInline: false,            // remove all inline styles from print elements
      removeInlineSelector: "",       // custom selectors to filter inline styles. removeInline must be true
      printDelay: 333,                // variable print delay
      header: null,                   // prefix to html
      footer: null,                   // postfix to html
      base: false,                    // preserve the BASE tag, or accept a string for the URL
      formValues: true,               // preserve input/form values
      canvas: true,                  // copy canvas elements
      doctypeString: '...',           // enter a different doctype for older markup
      removeScripts: false,           // remove script tags from print content
      copyTagClasses: true,           // copy classes from the html & body tag
      beforePrintEvent: null,         // callback function for printEvent in iframe
      beforePrint: null,              // function called before iframe is filled
      afterPrint: null                // function called before iframe is removed
    });
  }

  public SalvarPDF()
  {
    var nome = this.nomeDentista;
    var produto1 = this.descontoProduto1;
    var produto2 = this.descontoProduto2;
    var produto3 = this.descontoProduto3;
    var produto4 = this.descontoProduto4;
    var produto5 = this.descontoProduto5;
    var produto6 = this.descontoProduto6;
    var produto7 = this.descontoProduto7;
    var produto8 = this.descontoProduto8;
    var cupons = this.cupons;

    var pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px'
    });
    var img = new Image;
    img.onload = function () {

      for (var i = 0; i < cupons.length; i++) {
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'regular');
        pdf.setTextColor(0, 0, 0)
        pdf.addImage(this, 'PNG', 0, 0, 635, 480);
        pdf.text(nome, 330, 26);
        pdf.text(cupons[i], 490, 26);
        pdf.setFontSize(18);
        pdf.setFont('helvetica', 'bold');

        if ((i + 1) != cupons.length)
          pdf.addPage();
      }

      var data = new Date();
      var dia = data.getDate(); 
      var mes = ((data.getMonth() + 1) < 10) ? ("0" + (data.getMonth() + 1)) : ((data.getMonth() + 1));  
      var ano4 = data.getFullYear();       

      var str_data = dia + "" + mes + "" + ano4;

      pdf.save("KIN ODONTOCARE - " + cupons.length + " cupons - " + str_data + ".pdf");
    };

    img.crossOrigin = "";
    img.src = "assets/img/cupomDesconto.png";
  }

  public EscolherEmail()
  {
    $('.modal')
      .on({
        'show.bs.modal': function () {
          var idx = $('.modal:visible').length;

          $(this).css('z-index', 1040 + (10 * idx));
          var url = $(this).find('[data-url]').data('url');
          if (url != undefined && url != '') {
            var id = $(this).attr('id');
            $('#' + id + ' .modal-body').load(url);
          }
        },
        'shown.bs.modal': function () {
          var idx = ($('.modal:visible').length) - 1; // raise backdrop after animation.
          $('.modal-backdrop').not('.stacked')
            .css('z-index', 1039 + (10 * idx))
            .addClass('stacked');
        },
        'hidden.bs.modal': function () {
          if ($('.modal:visible').length > 0) {
            // restore the modal-open class to the body element, so that scrolling works
            // properly after de-stacking a modal.
            setTimeout(function () {
              $(document.body).addClass('modal-open');
            }, 0);
          }
        }
      });

    $("#myModal2").modal('hide');
    $("#myModal").modal('show');
  }

  public fecharEmail()
  {
    $("#myModal3 .close").click()
    $("#myModal2").modal('show');
  }

  public fecharEmail2()
  {
    $("#myModal .close").click()
    $("#myModal2").modal('show');
  }

  public EmailEnviado()
  {
    $("#myModal3 .close").click()
    $("#myModal2").modal('show');
  }
  
  public EnviarEmail()
  {
    this.carregando = true;

    this.emailv.destinatario = $("#emailEnviado").val();
    this.emailv.numeroCupom = this.numeroCupom;
    this.emailv.nomeDentista = this.nomeDentista;
    this.emailv.descontos = [];
    this.emailv.descontos.push(this.descontoProduto1);
    this.emailv.descontos.push(this.descontoProduto2);
    this.emailv.descontos.push(this.descontoProduto3);
    this.emailv.descontos.push(this.descontoProduto4);
    this.emailv.descontos.push(this.descontoProduto5);
    this.emailv.descontos.push(this.descontoProduto6);
    this.emailv.descontos.push(this.descontoProduto7);
    this.emailv.descontos.push(this.descontoProduto8);

    this.cadastroService
          .EnviarEmailGerarCupomOdontoCare(this.emailv)
          .then((res: Retorno) =>
          {
            if (res.sucesso)
            {
              $("#myModal .close").click()
              $("#myModal3").modal('show');
            }
            else
            {
              this.retorno.erro = "Erro ao enviar e-mail"
              $('#myModalErro').modal('show');
            }

            this.carregando = false;
          })
          .catch(() => this.mostrarErroInterno);
  }
  
  public AnimacaoComoFunciona()
  {
    $('html, body').animate({
      scrollTop: $('#como-funciona').offset().top
    }, 1000);
  }

  public AnimacaoOndeEncontrar()
  {
    $('html, body').animate({
      scrollTop: $('#onde-encontrar').offset().top
    }, 1000);
  }

  public AnimacaoCadastrar()
  {
    $('html, body').animate({
      scrollTop: $('#banner').offset().top
    }, 1000);
  }

  public abrirTermo()
  {
    $('#TermoModal').modal('show');
  }

  public onGerarCupons()
  {
    this.carregando = true;

    this.user.cpf = this.gerarCupomDentistaForm.controls['cpf'].value.toString().replace(/\.|\-/g, '');
    this.user.cro = this.gerarCupomDentistaForm.controls['croDentista'].value;
    this.user.uf = this.gerarCupomDentistaForm.controls['ufDentista'].value;
    this.user.qtdCupons = this.gerarCupomDentistaForm.controls['qtdCupons'].value;

    this.nomeDentista = this.user.cro + " " + this.user.uf;

    this.cadastroService
          .ConsultarProdutos()
          .then((res: RetornoProdutos) =>
          {
            this.retornoProdutos = res;
            
            if (res.sucesso)
            {
              const { produtosLista } = this.retornoProdutos.produtos;

              //this.descontoProduto1 = produtosLista.find(p => p.codigoProduto === '8470003847766').descontoProduto;
              //this.descontoProduto2 = produtosLista.find(p => p.codigoProduto === '8436026213797').descontoProduto;
              //this.descontoProduto3 = produtosLista.find(p => p.codigoProduto === '8436026212103').descontoProduto;
              //this.descontoProduto4 = produtosLista.find(p => p.codigoProduto === '8436026212103').descontoProduto;
              //this.descontoProduto5 = produtosLista.find(p => p.codigoProduto === '8470001590879').descontoProduto;
              //this.descontoProduto6 = produtosLista.find(p => p.codigoProduto === '8470001840899').descontoProduto;
              //this.descontoProduto7 = produtosLista.find(p => p.codigoProduto === '8470001551467').descontoProduto;
              //this.descontoProduto8 = produtosLista.find(p => p.codigoProduto === '8470003111546').descontoProduto;

              this.cadastroService
                    .GerarCupons(this.user)
                    .then((res: Retorno) =>
                    {
                      this.retorno = res;
                      
                      if (res.sucesso)
                      {
                        this.cupons = this.retorno.tokens;
                        this.numeroCupom = this.retorno.tokens[0];
                        this.paginaIndex = 0;
                        this.paginaTotal = this.retorno.tokens.length;
                        this.paginaAtual = 1;
                        $('#myModal2').modal('show');
                      }
                      else
                        $('#myModalErro').modal('show');

                      this.carregando = false;
                    })
                    .catch(() => this.mostrarErroInterno);
            }
          })
          .catch(() => this.mostrarErroInterno());
  }

  public mostrarErroInterno()
  {
    this.retorno.erro = 'Erro interno'; 
    $('#myModalErro').modal('show');
    this.carregando = false;
  }

  public cssObrigatorio(nomecampo)
  {
    if (this.gerarCupomDentistaForm.controls[nomecampo].invalid && (this.gerarCupomDentistaForm.controls[nomecampo].touched || this.gerarCupomDentistaForm.controls[nomecampo].dirty)) {
      $("#" + nomecampo).css("border-color", "#a94442");
    }
    if (this.gerarCupomDentistaForm.controls[nomecampo].valid) {
      $("#" + nomecampo).css("border-color", "#0c7e7d");
    }
  }
}

export class GenericValidator
{
  constructor() { }

  /**
   * Valida se o CPF é valido
  */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value.toString().replace(/\.|\-/g, '');
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }
}

export function cpfValidator(): ValidatorFn
{
  return (control: AbstractControl): { [key: string]: any } | null => {
    var cpfNotValid = false;
    if (control.value) {
      var cpf = control.value.toString().replace(/\.|\-/g,'');
     
      let numbers, digits, sum, i, result, equalDigits;
      equalDigits = 1;


      if (cpf.length > 1) {
        cpfNotValid = true;
      }

      if (cpf.length === 11) {

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result === Number(digits.charAt(0))) {
            numbers = cpf.substring(0, 10);
            sum = 0;

            for (i = 11; i > 1; i--) {
              sum += numbers.charAt(11 - i) * i;
            }
            result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (result === Number(digits.charAt(1))) {
              cpfNotValid = false;
            }
          }
        }
      }
    }
    return cpfNotValid ? { 'cpfInvalido': true } : null;
  }
};

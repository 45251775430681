import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { throwError } from 'rxjs';


export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

export function CustomHttpClientCreator(http: HttpClient, spinner: NgxSpinnerService) {
    return new CustomHttpClient(http, spinner);
}

@Injectable()
export class CustomHttpClient {
    constructor(private http: HttpClient,
                private spinner: NgxSpinnerService) { }
    
    public Get(endPoint: string, options?: IRequestOptions): Observable<Object> {
        this.showLoader();
        return this.http.get(endPoint, this.addJwt(options))
            .catch(this.handleError)
            .finally(() => this.OnEnd());
    }

    public Post(endPoint: string, body: any, options?: IRequestOptions): Observable<Object> {
        this.showLoader();
        return this.http.post(endPoint, body, this.addJwt(options))
            .catch(this.handleError)
            .finally(() => this.OnEnd());
    }

    private OnEnd(): void {
        this.hideLoader();
    }

    private showLoader(): void {
        this.spinner.show();
    }

    private hideLoader(): void {
        this.spinner.hide();
    }

  private handleError(error: Response | any) {
    return throwError(error);
  }

    private addJwt(options?: IRequestOptions): IRequestOptions {
        let requestOptions: IRequestOptions = { headers: new HttpHeaders() };
        options = options || requestOptions;
        return options;
    }

}
